import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { AnimatedLine } from '@mui/x-charts';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';

function CustomAnimatedLine(props) {
  const { limit, sxBefore, sxAfter, ...other } = props;
  const { top, bottom, height, left, width } = useDrawingArea();
  const scale = useXScale();
  const chartId = useChartId();

  if (limit === undefined) {
    return <AnimatedLine {...other} />;
  }

  const limitPosition = scale(limit); // Convert value to x coordinate.

  if (limitPosition === undefined) {
    return <AnimatedLine {...other} />;
  }

  const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
  const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
  return (
    <React.Fragment>
      {/* Clip to show the line before the limit */}
      <clipPath id={clipIdleft}>
        <rect
          x={left}
          y={0}
          width={limitPosition - left}
          height={top + height + bottom}
        />
      </clipPath>
      {/* Clip to show the line after the limit */}
      <clipPath id={clipIdRight}>
        <rect
          x={limitPosition}
          y={0}
          width={left + width - limitPosition}
          height={top + height + bottom}
        />
      </clipPath>
      <g clipPath={`url(#${clipIdleft})`}>
        <AnimatedLine {...other} sx={sxBefore} />
      </g>
      <g clipPath={`url(#${clipIdRight})`}>
        <AnimatedLine {...other} sx={sxAfter} />
      </g>
    </React.Fragment>
  );
}


const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const newData = [1, 1, 2, 3, 5, 6, 7, 5, 1 , 3, 4, 4];
const oldData = [3, 4, 5, 6, 7, 8, 9, 8, 5, 5, 8, 8];


export function BenefitGraph() {
  return (
    <LineChart
      series={[
        {
          type: 'line',
          data: newData,
          valueFormatter: (v, i) => `${v}${i.dataIndex > 5 ? ' (estimated)' : ''}`,
          name: 'Dashed Line',
          //color: 'rgb(41, 98, 0)',
          id: 'new',
        //   label: '2024',
        },
        {
          type: 'line',
          data: oldData,
          valueFormatter: (v) => `${v}`,
          name: 'Solid Line',
          color: '#858585',
          id: 'old',
        //   label: '2023',
        },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'point' }]}
      height={275}
      width={325}
      slots={{ line: CustomAnimatedLine }}
      margin={{
        left: 20,
        right: 10,
        top: 10,
        bottom: 20,
      }}
      slotProps={{ line: { limit: 'Sep', sxAfter: { strokeDasharray: '10 5' } } }}
      sx={{
        '.MuiLineElement-series-old' : {
            strokeDasharray: 'none'
        }
      }}
    />
  );
}
