import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../AppContext';
import './NoBuildings.css'

const NoBuildings = ({ onSubmit }) => {
    const [code, setCode] = useState(Array(5).fill(''));
    const [error, setError] = useState('');

    const { setIsNoBuildingsScreen } = useAppContext();

    useEffect(() => {
        setIsNoBuildingsScreen(true);
        return () => setIsNoBuildingsScreen(false);  // Reset state when unmounting
    }, [setIsNoBuildingsScreen]);

    const handleChange = (event, index) => {
        const value = event.target.value;
        if (/^\d$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            setError('');
    
            // Focus the next input field if the current one is filled
            if (value !== '' && index < 4) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        } else if (event.key === 'Backspace' && index > 0 && value === '') {
            // Focus the previous input field if the current one is empty
            document.getElementById(`code-input-${index - 1}`).focus();
        } else {
            setError('Building code must be a 5-digit number.');
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && code[index] === '') {
            // Focus the previous input field if the current one is empty
            document.getElementById(`code-input-${index - 1}`).focus();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const buildingCode = code.join('');
        if (buildingCode.length === 5) {
            // onSubmit(buildingCode);
            console.log(buildingCode);
        } else {
            setError('Please enter a valid 5-digit building code.');
        }
    };

    return (
        <div className="welcome-message-container">
            <h2>Welcome to my Building Alerts</h2>
            <p>Enter the code for your building to access your alerts</p>
            <form onSubmit={handleSubmit}>
                <div className="code-input-container">
                    {code.map((digit, index) => (
                        <input
                            key={index}
                            id={`code-input-${index}`}
                            type="text"
                            value={digit}
                            onChange={(event) => handleChange(event, index)}
                            onKeyDown={(event) => handleKeyDown(event, index)}
                            maxLength="1"
                            className="code-input"
                        />
                    ))}
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default NoBuildings;