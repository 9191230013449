import React, { useEffect, useState } from 'react';
import './AlertInfoPage.css';
import '../NewHome.css';
import { useLocation } from 'react-router-dom';

import AlertHeader from './AlertHeader';
import AlertDetails from './AlertDetails';
import SensorDataTable from './SensorDataTable';
import AssignAlert from './AssignAlert';
import AlertService from '../../../services/AlertService';
import AlertStatus from './AlertStatus';

const AlertDetailsPage = () => {
    const location = useLocation();
    const { alertType, alertId, id, alertSeverity, date, alertName, metadata, device, 
        address, unit, building, suite, user_id, assignedPersonnel, assign, status, serviceId } = location.state || {};
    
    const [deviceArray, setDevices] = useState(null);
    
    const formatDeviceIds = (data) => {
        return data.map(item => item.deviceId).join(',');
    };
    
    useEffect(() => {
        const fetchAlert = async (formattedIds) => {
            try {
                const deviceData = await AlertService.getDevicesByIds(formattedIds);
                setDevices(deviceData);
            } catch (error) {
                console.error('Failed to fetch devices:', error);
            }
        };

        if (metadata && Array.isArray(metadata.data)) {
            const formattedIds = formatDeviceIds(metadata.data);
            fetchAlert(formattedIds);
        }
    }, [metadata]);

    return (
        <div className="AlertDetails" style={{display: 'flex', flexDirection: 'column', 
            justifyContent: 'center'}}>
        <div className="alerts-section height-wrapper">
            <AlertHeader 
                alertType={alertType} 
                alertId={alertId} 
            />
            <div className='content-wrapper'>
                <AlertDetails
                    alertType={null}
                    alertId={id}
                    alertSeverity={alertSeverity}
                    date={date}
                    description={null}
                    address={address}
                    unit={unit}
                    alertStatus={null}
                    alertName={alertName}
                /> 
                {/* 
                /* For the alert details pages
                */}
                {device && (
                    <SensorDataTable
                        metadata={metadata}
                        device={device}
                    />
                )}
                {deviceArray && !assign && !status && (
                    <SensorDataTable
                        metadata={metadata}
                        device={deviceArray}
                    />
                )}
                
                {/* 
                /* For the assign alert pages
                */}
                {assign && (
                    <AssignAlert
                        alertId={id}
                        building={building}
                        suite={suite}
                        user_id={user_id}
                        assignedPersonnel={assignedPersonnel}
                    />  
                )} 

                {/* 
                /* For the alert status pages
                */}
                {status && (
                    <AlertStatus
                        currentStatus={status}
                        alertId={id}
                        serviceId={serviceId}
                    />  
                )}
            </div>
        </div>
        </div>
    );
};

export default AlertDetailsPage