import React, { useState, useEffect } from 'react';
import '../../MainDash/Alerts/AlertInfoPage.css';
import '../../MainDash/NewHome.css';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton } from "@mui/material";
import AlertService from '../../../services/AlertService';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const DataPage = () => {
    const history = useHistory();
    const location = useLocation();
    const [suites, setSuites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    const { item, type } = location.state;

    useEffect(() => {
        const fetchSuites = async () => {
            try {
                if (type === 'Tenant') {
                    const suiteData = await AlertService.getSuiteById(item.suiteId);
                    setSuites([suiteData]); // Store the suite data in an array
                    setIsLoading(false);
                } else if (type === 'Building') {
                    const suitePromises = item.suites.map((suiteId) =>
                    AlertService.getSuiteById(suiteId)
                    );
                    const suiteData = await Promise.all(suitePromises);
                    setSuites(suiteData); // Set the fetched suite data
                    setIsLoading(false);
                }   
            } catch (error) {
                console.error(`Failed to fetch suite:`, error);
                setIsLoading(false);
            }
        };
        fetchSuites();
    }, [type, item.suiteId, item.suites]);
    
    console.log(item);

    const renderBuildingDetails = () => (
        <>
            {item.name && (
                <div className='alert-row'>
                    <div className='alert-title'>Name</div>
                    <div className='alert-value'>{item.name}</div>
                </div>
            )}
            {item.address && (
                <div className='alert-row'>
                    <div className='alert-title'>Address</div>
                    <div className='alert-value'>{item.address}</div>
                </div>
            )}
            {item.contactPhone && (
                <div className='alert-row'>
                    <div className='alert-title'>Contact Phone</div>
                    <div className='alert-value'>{item.contactPhone}</div>
                </div>
            )}
            {item.contactEmail && (
                <div className='alert-row'>
                    <div className='alert-title'>Contact Email</div>
                    <div className='alert-value'>{item.contactEmail}</div>
                </div>
            )}
            {item.propertyManager && item.propertyManager.length > 0 && (
                <div className='alert-row'>
                    <div className='alert-title'>Property Manager</div>
                    <div className='alert-value'>
                        {/* {propertyManager.map(manager => (
                            <div key={manager.id}>{manager.name}</div>
                        ))} */}
                    </div>
                </div>
            )}
            {item.serviceContractor && item.serviceContractor.length > 0 && (
                <div className='alert-row'>
                    <div className='alert-title'>Service Contractor</div>
                    <div className='alert-value'>
                        {item.serviceContractor.map(contractor => (
                            <div key={contractor.id}>{contractor}</div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );

    const renderTenantDetails = () => (
        <>
            {item.name && (
                <div className='alert-row'>
                    <div className='alert-title'>Name</div>
                    <div className='alert-value'>{item.name}</div>
                </div>
            )}
            {item.buildingId && (
                <div className='alert-row'>
                    <div className='alert-title'>Building ID</div>
                    <div className='alert-value'>{item.buildingId}</div>
                </div>
            )}
        </>
    );

    const renderSuiteDetails = () => (
        <>
            {isLoading ? (
                <div className='loader-container'>
                    <div className="loader"></div>
                </div>
            ) : (
                <div className='alert-row'>
                    <div className='alert-title'>
                        {type === 'Building' ? 'Suites' : 'Suite'}
                    </div>
                    <div className='suite-list'>
                        {suites.filter(suite => suite !== null).map((suite) => (
                        <div key={suite._id} className="suite">
                            {suite && (
                                <>
                                    <h3>Unit {suite.suite}</h3>
                                    <p>{suite.occupied ? 'Occupied' : 'Not Occupied'}</p>
                                    <p>{suite.squareFootage ? `${suite.squareFootage} Sqft` : 'N/A'}</p>
                                </>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );



    return (
        <div className="AlertPage">
            <div className="alerts-section">
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)' }}
                            />
                        </IconButton>
                        <h2>{type === 'Building' ? 'Building Details' : 'Tenant Details'}</h2>
                        <div className="placeholder" style={{ width: '40px', backgroundColor: 'transparent' }}></div>
                    </div>
                </div>
                <div className='alert-cols' style={{width: '100%'}}>
                    {type === 'Building' ? renderBuildingDetails() : renderTenantDetails()}
                    {renderSuiteDetails()}
                </div>
            </div>
        </div>
    );
};

export default DataPage;
