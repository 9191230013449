import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './AlertInfoPage.css';
import '../NewHome.css';
import './AssignAlert.css';
import Attach from '../../../images/attach.png'
import AlertModal from './AlertModal';
import { updateServiceDescription, uploadServiceImage } from '../../../services/ServiceRecordService';

const ServiceNeeded = ({serviceTypes, height, title, resolved, selectedOption, alertId, updateStatus, serviceId, serviceType}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();
    const [serviceRecordFile, setServiceRecordFile] = useState(null);
    const [optionalPhoto, setOptionalPhoto] = useState(null);
    const [description, setDescription] = useState(""); // State for textarea content
    const [invoiceFile, setInvoiceFile] = useState(null);

    // State for file attachment indicators
    const [serviceRecordFileName, setServiceRecordFileName] = useState('');
    const [optionalPhotoName, setOptionalPhotoName] = useState('');
    const [invoiceFileName, setInvoiceFileName] = useState('');
    
    //hard coded for testing
    const [serviceOptions, setServiceOptions] = useState({
        replaceBatteries: false,
        replaceThermistor: false,
        changeSettings: false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setServiceOptions(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleFileChange = (event) => {
        const { id, files } = event.target;
        if (files.length > 0) {
            const fileName = files[0].name;
            if (id === 'service-record-upload') {
                setServiceRecordFile(files[0]);
                setServiceRecordFileName(fileName);
            } else if (id === 'invoice-upload') {
                setInvoiceFile(files[0]);
                setInvoiceFileName(fileName);
            } else if (id === 'photo-upload') {
                setOptionalPhoto(files[0]);
                setOptionalPhotoName(fileName);
            }
        }
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleButtonClick = async () => {
        if (resolved) {
            if (!serviceRecordFile || !invoiceFile) {
                alert('Please upload both the service record and the invoice.');
                return;
            }
        }
        console.log('service needed button clicked', serviceOptions);
        
        const serviceUpdated = await updateService();
            if (serviceUpdated) {
                if (serviceRecordFile) {
                    await uploadServiceImage(serviceId._id, serviceRecordFile, serviceType);
                }
                if (optionalPhoto) {
                    await uploadServiceImage(serviceId._id, optionalPhoto, serviceType);
                }
                if (invoiceFile) {
                    await uploadServiceImage(serviceId._id, invoiceFile, serviceType);
                }
                await updateStatus(alertId, selectedOption);
                setIsModalOpen(true);
            }
    };

    const updateService = async () => {
        try {
            const service = await updateServiceDescription(serviceId._id, serviceType, description);
            console.log(service);
            return true;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        }
    };

    return (
        <>
        <div className="service-needed-content" style={{height}}>
            {serviceTypes && (
                <>
                <h2 className='text-header'>Select service:</h2>
                <div className="checkbox-container">
                    <label>
                        <input
                            type="checkbox"
                            name="replaceBatteries"
                            checked={serviceOptions.replaceBatteries}
                            onChange={handleCheckboxChange}
                        />
                        Thermostat - Replace Batteries
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="replaceThermistor"
                            checked={serviceOptions.replaceThermistor}
                            onChange={handleCheckboxChange}
                        />
                        Thermostat - Replace Replace Thermistor
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="changeSettings"
                            checked={serviceOptions.changeSettings}
                            onChange={handleCheckboxChange}
                        />
                        Thermostat - Change Settings
                    </label>
                </div>
            </>
            )}

            <h2 className='text-header'>{title}</h2>
            <textarea
                    type="text"
                    placeholder="Type your comment"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
            />
            {!resolved ? (
                <label htmlFor="photo-upload" className='photo-label'>
                    <img 
                        src={Attach}
                        alt="upload icon" 
                        style={{ height: '20px', marginLeft: '10px' }} 
                    />
                    {optionalPhotoName ? optionalPhotoName : 'Attach a photo (optional)'}
                    <input 
                        type="file" 
                        id="photo-upload" 
                        accept="image/*" 
                        style={{ padding: '5px', marginTop: '5px' }}
                        onChange={handleFileChange}
                    />
                </label>
            ) : (
                <div>
                    <label htmlFor="photo-upload" className='photo-label'>
                    <img 
                        src={Attach}
                        alt="upload icon" 
                        style={{ height: '20px', marginLeft: '10px' }} 
                    />
                    {optionalPhotoName ? optionalPhotoName : 'Attach a photo (optional)'}
                    <input 
                        type="file" 
                        id="photo-upload" 
                        accept="image/*" 
                        style={{ padding: '5px', marginTop: '5px' }}
                        onChange={handleFileChange}
                    />
                    </label>
                    <label htmlFor="service-record-upload" className='photo-label'>
                    <img 
                        src={Attach}
                        alt="upload icon" 
                        style={{ height: '20px', marginLeft: '10px' }} 
                    />
                    {serviceRecordFileName ? serviceRecordFileName : 'Upload a copy of service record'}
                    <input 
                        type="file" 
                        id="service-record-upload"
                        accept="image/*,application/pdf,application/msword,application/
                            vnd.openxmlformats-officedocument.wordprocessingml.document,application/
                            vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                        onChange={handleFileChange}
                        style={{ padding: '5px', marginTop: '5px' }}
                    />
                    </label>
                    <label htmlFor="invoice-upload" className='photo-label'>
                    <img 
                        src={Attach}
                        alt="upload icon" 
                        style={{ height: '20px', marginLeft: '10px' }} 
                    />
                    {invoiceFileName ? invoiceFileName : 'Upload a copy of invoice'}
                    <input 
                        type="file" 
                        id="invoice-upload"
                        accept="image/*,application/pdf,application/msword,application/
                            vnd.openxmlformats-officedocument.wordprocessingml.document,application/
                            vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                        onChange={handleFileChange}
                        style={{ padding: '5px', marginTop: '5px' }}
                    />
                    </label>
                </div>
            )}
            <div className='alert-button-container'>
                <button className='alert-info-button' 
                    onClick={handleButtonClick}>
                    Save Changes
                </button>
            </div>
        </div>
        <AlertModal 
            isModalOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)} 
            confirmModal={handleGoBack} 
            handleOptionClick={() => {}} 
            selectedOption={selectedOption} 
            height="200px"
            title="Success!"
            options={[]}
        >
            <p>Status changed to {selectedOption}.</p>
            <button className="modal-button alert-confirm" 
                onClick={handleGoBack}>Return to Alert Description</button>
        </AlertModal>
        </>
    );
};
export default ServiceNeeded