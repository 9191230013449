import React from 'react';
import logo from '../images/New Logo.png';
import Menu from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Link } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { useHistory } from "react-router-dom";
import './Header.css';

const Header = ({ isMenuOpen, onMenuClick, isBuildingMenuOpen, onBuildingClick }) => {
  const { isNoBuildingsScreen } = useAppContext();
  const history = useHistory();

  const handleLogout = () => {
    // Clear the user's auth state, e.g., removing token from localStorage
    localStorage.setItem('isLoggedIn', 'false');
    history.push("/");
  };

  return (
    <div className="header-container">
      {!isNoBuildingsScreen && (
        <>
          {isMenuOpen ? (
            <CloseIcon className='menu-icon' onClick={onMenuClick} />
          ) : (
            <Menu className='menu-icon' onClick={onMenuClick} />
          )}
        </>
      )}
      <Link to="/Home">
        <img src={logo} alt="myBuilding Alerts" className="logo"/>
      </Link>
      {!isNoBuildingsScreen && (
        <>
          {isBuildingMenuOpen ? (
            <CloseIcon fontSize="medium" className='menu-icon' onClick={onBuildingClick} />
          ) : (
            <ApartmentIcon alt='All Buildings' fontSize="medium" className='menu-icon' 
              onClick={onBuildingClick} />
          )}
        </>
      )}
      {isNoBuildingsScreen && (
        <button onClick={handleLogout} className="menu-option" style={{color: '#5C5B49', fontSize: '18px'}}>Logout</button>
      )}
    </div>
  );
};

export default Header;