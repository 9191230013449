import React, { useEffect, useState } from 'react';
import '../../MainDash/Alerts/AlertInfoPage.css';
import '../../MainDash/NewHome.css';
import { IconButton, TextField, Button } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AlertService from '../../../services/AlertService';
import { useHistory } from 'react-router-dom';
import { getUserId } from '../../../utils/AuthUtilities';
import { SettingsPhoneTwoTone } from '@material-ui/icons';

const Profile = () => {
    const history = useHistory();
    const user_id = getUserId();
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [permissions, setPermissions] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await AlertService.getUserById(user_id);
                setUser(userData);
                setName(userData.name);
                setEmail(userData.email);
                setPhone(userData.phone);
                setUsername(userData.username);
                setPermissions(userData.permissions);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [user_id]);

    const handleSave = async () => {
        try {
            const updatedUser = {
                name,
                email,
                phone,
                username,
                permissions,
            };
            console.log(updatedUser);
            //await AlertService.updateUser(user_id, updatedUser);
            //alert('Profile updated successfully!');
        } catch (error) {
            console.error('Failed to update user:', error);
            alert('Failed to update profile.');
        }
    };

    if (isLoading) {
        return <div className='loader-container'>
                    <div className="loader"></div>
                </div>;
    }

    return (
        <div className="AlertPage">
            <div className="alerts-section">
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.push('/Home')}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)' }}
                            />
                        </IconButton>
                        <h2>Profile</h2>
                        <div className="placeholder" style={{ width: '40px', backgroundColor: 'transparent' }}></div>
                    </div>
                </div>

                <div className="profile-fields">
                    <TextField
                        label="Name"
                        sx={{ m: '5px 10px 20px 0' }}
                        value={name}
                        variant="standard"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Username"
                        variant="standard"
                        sx={{ m: '5px 0px 20px 0' }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        variant="standard"
                        sx={{ m: '5px 0px 20px 0', width: '340px' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Phone"
                        variant="standard"
                        sx={{ m: '5px 10px 10px 0' }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <TextField
                        label="Permissions"
                        variant="standard"
                        sx={{ m: '5px 0 10px 0' }}
                        value={permissions}
                        onChange={(e) => setPermissions(e.target.value)}
                    />
                </div>

                <div className='alert-button-container'>
                    <button className='alert-info-button' 
                        onClick={handleSave}
                        style={{padding: '0.5em 1.25em', fontSize: '18px', marginBottom: '20px'}}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
