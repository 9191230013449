import * as React from 'react';
import { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { getUserId } from '../../../utils/AuthUtilities';
import Select from '@mui/material/Select';
import AlertService from '../../../services/AlertService';

export function BuildingDropdown({ selectedBuilding, onBuildingChange }) {
  const user_id = getUserId();
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const buildingData = await AlertService.getBuildingsByUserId(user_id);

        if (buildingData && buildingData.buildings) {
          setBuildings(buildingData.buildings);
        } else {
          console.error('Expected buildings array but received:', buildingData);
        }
      } catch (error) {
        console.error('Failed to fetch buildings:', error);
      }
    };

    fetchBuildings();
  }, [user_id]);

  const handleChange = (event) => {
    onBuildingChange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, width: 325 }}>
      <InputLabel id="building-dropdown-label" sx={{ zIndex: 0 }}>Building</InputLabel>
      <Select
        labelId="building-dropdown-label"
        id="building-dropdown"
        value={selectedBuilding}
        onChange={handleChange}
        input={<OutlinedInput label="Building" />}
        sx={{ fontFamily: 'Ubuntu' }}
      >
        {buildings.map((building) => (
          <MenuItem key={building._id} value={building._id}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
              <Typography variant="body1" style={{ whiteSpace: 'normal' }}>
                {building.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'normal' }}>
                {building.address}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
