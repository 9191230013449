import React, { useState, useEffect } from 'react';
import ProgBar from './ProgBar';
import { BenefitGraph } from './BenefitGraph';
import KeyCard from './KeyCard';
import BenefitStatusCard from '../BenefitStatusCard';

const ResourcePage = ({ resource }) => {
    const [benefitStatus, setBenefitStatus] = useState('');
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [titleUnit, setTitle] = useState('');
    const [titleColor, setColor] = useState('');

    // function for testing/product display - currently not connected to back end
    function getBenefitStatus(resource) {
        let status;
        let widthValue;
        let keyTitle;
        let color;
        let onScreen = true;

        if (resource === 'Electricity') {
            status = 'Achieving Goals';
            widthValue = '60%';
            keyTitle = 'kWh';
            color = 'rgb(41, 98, 0)';
            onScreen = false;
        } else if (resource === 'Water') {
            status = 'Action Needed';
            keyTitle = 'Gallons';
            color = '#00000099';
            widthValue = '74%';
        } else if (resource === 'Natural Gas') {
            status = 'Adapt Goals';
            keyTitle = 'Therms';
            color = '#00000099'
            widthValue = '67%';
        } else {
            status = 'Achieving Goals';
            widthValue = '60%';
            keyTitle = '$';
            color = 'rgb(41, 98, 0)';
        }

        setBenefitStatus(status);
        setWidth(widthValue);
        setTitle(keyTitle);
        setColor(color);
        setOnBenefitScreen(onScreen);
    }

    // useEffect to update benefit status and width based on resource
    useEffect(() => {
        getBenefitStatus(resource);
    }, [resource]);

    return (
        <>
            {/* <ProgBar value='0.75' label='Goal Increase' /> */}
            <div className='savings-header'><span style={{color: titleColor}}>28%</span> Reduction Achieved YTD</div>
            <div className='key'>
                <div className='key-title'>{titleUnit} Consumed</div>
                <div className='key-container'>
                    <KeyCard color='#858585' label='2023' />
                    <KeyCard color='#02B2AF' label='2024' />
                </div>
            </div>
            <BenefitGraph />
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus} 
                    width={width} 
                    onBenefitScreen={onBenefitScreen}
                />
                <div className='savings-data'>
                    <div className='savings'>Improvement Goal: <span>25%</span></div>
                    <div className='savings'>Estimated Reduction: <span>250 {titleUnit}</span></div>
                    <div className='savings'>Actual Reduction: <span style={{color: titleColor}}>300 {titleUnit}</span></div>
                </div>
            </div>
        </>
    );
};

export default ResourcePage;
