import React from 'react';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';

const AlertHeader = ({ alertType, alertId}) => {
    const history = useHistory();

    return (
        <div className="alerts-header small-screens">
            <div className="title-container">
                <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIosNewIcon
                        style={{ color: 'rgb(14, 81, 46)'}}
                    />
                </IconButton>
                <h2>{alertType} Alert {alertId}</h2>
                <div className="placeholder" style={{width: '40px', backgroundColor: 'transparent'}}></div>
            </div>
        </div>
    );
};

export default AlertHeader;