// AuthUtilities.js
export const getUser = () => {
    const user = localStorage.getItem('loginResponse');
    return user ? JSON.parse(user) : null;
};
  
export const getUserId = () => {
    const user = getUser();
    return user ? user._id : null;
};
  
export const getUserPermissions = () => {
    const user = getUser();
    return user ? user.permissions : null;
};
  
export const getCurrentBuilding = () => {
    const building = localStorage.getItem('currentBuilding');
    return building ? JSON.parse(building) : null;
};

export const setCurrentBuilding = (building) => {
    localStorage.setItem('currentBuilding', JSON.stringify(building));
};

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function checkCookieExpiration() {
    const token = getCookie('access_token');
    if (!token) {
        console.log('No access token found.');
        return true; // Consider token expired if not found
    }
    return false;
}