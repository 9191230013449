import React from 'react';
import { colors, camelCase } from "../../../constants/constants";

const AlertDetails = ({ alertType, alertId, alertSeverity, date, description, address, unit, alertStatus, alertName}) => {
    let backgroundColor = colors.primaryColor;
    switch (alertSeverity) {
        case 'High':
            backgroundColor = colors.alertBackground;
            break;
        case 'Low':
            backgroundColor = colors.lowBackground;
            break;
        case 'RE-CHECK':
            backgroundColor = colors.recheckBackground;
            break;
        default:
            backgroundColor = colors.greenBackground;
            break;
    }
    return (
        <div className='alert-cols'>
            {alertSeverity && (
            <div className='alert-row'>
                <div className='alert-title'>Severity</div>
                    <div className='alert-value'><span
                    style={{
                        textTransform: 'none',
                        borderRadius: '10px',
                        backgroundColor: backgroundColor,
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '8px 28px',
                    }}>{camelCase(alertSeverity)}</span></div>
            </div>  
            )}
            {alertName && (
            <div className='alert-row'>
                <div className='alert-title'>Alert</div>
                <div className='alert-value'>{alertName}</div>
            </div>
            )}
            {description && (
            <div className='alert-row'>
                <div className='alert-title'>Description</div>
                <div className='alert-value'>{description}</div>
            </div> 
            )}
            {date && (
            <div className='alert-row'>
                <div className='alert-title'>Date Reported</div>
                <div className='alert-value'>{date}</div>
            </div>    
            )}
            {address && (   
            <div className='alert-row'>
                <div className='alert-title'>Location</div>
                <div className='alert-value'>{address}</div>
            </div>
            )}
            {unit && (
            <div className='alert-row'>
                <div className='alert-title'>Unit</div>
                <div className='alert-value'>{unit}</div>
            </div>
            )}
            {alertId && (
            <div className='alert-row'>
                <div className='alert-title'>Reference ID</div>
                <div className='alert-value'>{alertId}</div>
            </div>
            )} 
            {alertStatus && (
                <hr style={{margin: '1em 3em 0.5em 3em'}}></hr>
            )}
            {alertStatus && (
            <div className='alert-row'>
                <div className='alert-title'>Status</div>
                <div className='alert-value'>{alertStatus}</div>
            </div> 
            )}         
        </div>
    );
};

export default AlertDetails;


