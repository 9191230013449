// AlertUtilities.js

export const getAlertType = (alertId) => {
    if (!alertId) return null;

    const firstChar = alertId.charAt(0).toLowerCase();
    switch (firstChar) {
        case 'h':
            return 'Health & Safety';
        case 't':
            return 'Thermal Comfort';
        case 'r':
            return 'Resource Efficiency';
        case 's':
            return 'Service Check';
        default:
            return 'Unknown';
    }
};

export const getAlertSeverity = (alertId) => {
    if (!alertId) return null;

    if (alertId.endsWith('R')) {
        return 'RE-CHECK';
    }

    // Extract the first number after the alphanumerical prefix
    const match = alertId.match(/\d/);
    const firstNumber = match ? parseInt(match[0], 10) : null;
    

    if (firstNumber === null) {
        return 'Unknown';
    } else if (firstNumber === 0) {
        return 'None';
    } else if (firstNumber >= 1 && firstNumber <= 2) {
        return 'Low';
    } else if (firstNumber >= 3 && firstNumber <= 5) {
        return 'High';
    } else {
        return 'Unknown';
    }
}

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Format options
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'America/New_York'
    };

    // Format date
    return new Intl.DateTimeFormat('en-US', options).format(date);
};