import React from 'react';
import { Box } from '@mui/material';

const KeyCard = ({ color, stroke, label }) => {
  return (
    <Box display="flex" alignItems="center" margin="5px" justifyContent='center'>
      <Box
        width={14}
        height={14}
        borderRadius="50%"
        bgcolor={color}
        border={`2px solid ${stroke}`}
        marginRight="10px"
      />
      <span style={{ fontSize: '16px', color: '#00000099' }}>{label}</span>
    </Box>
  );
};

export default KeyCard;
