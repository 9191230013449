import React from 'react';
import { useLocation } from 'react-router-dom';
import Report from './Report';

const SubmitPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    return <Report type={type}/>;
};

export default SubmitPage;