import React, { useState } from 'react';
import './AlertInfoPage.css';
import '../NewHome.css';
import './AssignAlert.css';

import AssignContent from './AssignContent';

const AssignAlert = ({alertId, building, suite, user_id, assignedPersonnel}) => {
    const [selectedOption, setSelectedOption] = useState('');
 
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="assign-alert">
            <hr style={{margin: '0 0 1.5em 0'}}></hr>
            <div className="dropdown-container">
                <label htmlFor="assign-alert-dropdown" style={{fontWeight: 'bold', margin: '0'}}>Assign Alert to:</label>
                <select 
                    id="assign-alert-dropdown" 
                    value={selectedOption} 
                    onChange={handleOptionChange}
                    className="assign-alert-dropdown"
                >
                    <option value="">Select an option</option>
                    <option value="Service Contractor">Service Contractor</option>
                    <option value="Tenant">Tenant</option>
                    <option value="Building Team">Building Team</option>
                </select>
            </div>

        {selectedOption && (
            <div className="selected-option-content">
                {selectedOption === '' ? (
                    <div className='no-select'>Please choose someone to assign the alert to.</div>
                ) : (
                    <AssignContent
                        selectedOption={selectedOption}
                        alertId={alertId}
                        building={building}
                        suite={suite}
                        user_id={user_id}
                        assignedPersonnel={assignedPersonnel}
                    />
                )}

            </div>
        )}
        </div>
    );
};
export default AssignAlert