import React, { useState, useEffect } from 'react';
import './AlertPage.css';
import '../NewHome.css';
import { useHistory } from 'react-router-dom';
import Add from '../../../images/add-icon.png';
import Progress from '../../../images/inprogress-icon.png';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// import Footer from '../Footer';


const AlertPage = ({ title, children }) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('new'); // State to manage active tab

    // Separate children based on the status prop
    const activeChildren = React.Children.toArray(children).filter(child => child && (child.props.status === 'Active' || child.props.status === 'New'));
    const inProgressChildren = React.Children.toArray(children).filter(child => child && !(child.props.status === 'Active' || child.props.status === 'New'));

    const hasActiveAlerts = activeChildren.length > 0;
    const hasInProgressAlerts = inProgressChildren.length > 0;

    useEffect(() => {
        // Retrieve the active tab from localStorage when the component mounts
        const savedActiveTab = localStorage.getItem('activeTab');
        if (savedActiveTab) {
            setActiveTab(savedActiveTab);
        }
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab); // Save the active tab to localStorage
    };

    return (
        <div>
        <div className="AlertPage">
            <div className="alerts-section height-wrapper">
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)'}}
                            />
                        </IconButton>
                        <h2>{title}</h2>
                        <div className="placeholder" style={{width: '40px', backgroundColor: 'transparent'}}></div>
                    </div>
                </div>
                <div className="alert-body">
                    <nav className="alert-tabs">
                        <div className={`tab-container ${activeTab === 'new' ? 'active' : ''}`} onClick={() => handleTabClick('new')}>
                            <ul>New</ul>
                            <img src={Add} alt="New Alerts" />
                        </div>
                        <div className={`tab-container ${activeTab === 'in-progress' ? 'active' : ''}`} onClick={() => handleTabClick('in-progress')}>
                            <ul>In-Progress</ul>
                            <img src={Progress} alt="In-Progress Alerts" />
                        </div>
                    </nav>
                    <div className="alert-display">
                        <div className="alert-heading">
                                    <div className="heading-titles">
                                        <h3>Severity</h3>
                                        <div className="w-10" />
                                        <h3>Description</h3>
                                    </div>
                                    <div className="placeholder"></div>
                        </div>
                            {activeTab === 'new' && (
                                <div className="new">
                                    {hasActiveAlerts ? activeChildren : children}
                                </div>
                            )}
                            {activeTab === 'in-progress' && (
                                <div className="in-progress">
                                    {hasInProgressAlerts ? inProgressChildren : children}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
          {/* <Footer /> */}
        </div>
    );
};

export default AlertPage;


