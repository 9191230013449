import React, { useEffect, useState } from 'react';
import './AlertInfoPage.css';
import '../NewHome.css';
import AlertService from '../../../services/AlertService';
import AlertHeader from './AlertHeader';
import AlertDetails from './AlertDetails';
import { getAlertType, getAlertSeverity, formatTimestamp } from '../../../utils/AlertUtilities';
import { useHistory, useParams } from 'react-router-dom';
import { getUserId } from '../../../utils/AuthUtilities';

import ServiceAcknowledged from './ServiceAcknowledged';

const AlertInfoPage = () => {
    const history = useHistory();
    const { id } = useParams();
    const user_id = getUserId();

    const [alert, setAlert] = useState(null);
    const [building, setBuilding] = useState(null);
    const [suite, setSuite] = useState(null);

    useEffect(() => {
        const fetchAlert = async () => {
            try {
                const alertData = await AlertService.getAlertById(id);
                console.log(alertData);
                setAlert(alertData);
                
                // Fetch building data once you have the alert data
                if (alertData && alertData.buildingId) {
                    const buildingData = await AlertService.getBuildingById(alertData.buildingId);
                    setBuilding(buildingData);
                }
                if (alertData && alertData.deviceId.location.suiteId) {
                    const suiteData = await AlertService.getSuiteById(alertData.deviceId.location.suiteId);
                    setSuite(suiteData);
                }
            } catch (error) {
                console.error('Failed to fetch alert:', error);
            }
        };

        fetchAlert();
    }, [id]);

    if (!alert) {
        return <div className='loader-container'>
                    <div className="loader"></div>
                </div>;
    }

    const alertType = getAlertType(alert.alert_id);
    const alertSeverity = getAlertSeverity(alert.alert_id);
    const date = formatTimestamp(alert.timestamp);

    const handleViewAlertData = () => {
        history.push(`/alert/${id}/details`, {
            alertType,
            alertId: alert.alert_id,
            id: alert._id,
            alertSeverity,
            date,
            alertName: alert.alert_name,
            metadata: alert.metadata,
            device: alert.deviceId,
            address: null,
            unit: null,
            building: null,
            suite: null,
            user: null,
            assignedPersonnel: null,
            assign: null,
            status: null,
            serviceId: null
        });
    };
 
    const handleAssignAlert = () => {
        history.push(`/alert/${id}/details`, {
            alertType,
            alertId: alert.alert_id,
            id: alert._id,
            alertSeverity,
            date: null,
            alertName: alert.alert_name,
            metadata: alert.metadata,
            device: null,
            address: null,
            unit: null,
            building: building,
            suite: suite,
            user_id: user_id,
            assignedPersonnel: alert.assignedPersonnel || [],
            assign: 'assign',
            status: null,
            serviceId: null
        });
    };

    const handleAlertStatus = () => {
        history.push(`/alert/${id}/details`, {
            alertType,
            alertId: alert.alert_id,
            id: alert._id,
            alertSeverity,
            date: null,
            alertName: alert.alert_name,
            metadata: alert.metadata,
            device: null,
            address: null,
            unit: null,
            building: building,
            suite: suite,
            user_id: user_id,
            assignedPersonnel: alert.assignedPersonnel || [],
            assign: null,
            status: alert.status,
            serviceId: alert.serviceRecordId
        });
    };
 

    return (
        <div>
        <div className="AlertPage">
            <div className="alerts-section">
                <AlertHeader 
                        alertType={alertType} 
                        alertId={alert.alert_id} 
                />
                <div className='info-page' style={{width: '100%'}}>
                    <div className='content-container'
                         style={alert.status === 'Investigating' ? { height: '510px' } : {}}>
                        <AlertDetails
                            alertType={alertType}
                            alertId={alert._id}
                            alertSeverity={alertSeverity}
                            date={date}
                            description={alert.alert_desc}
                            address={building ? building.address : 'Loading...'}
                            unit={suite ? suite.suite : 'Loading...'}
                            alertStatus={alert.status}
                            alertName={alert.alert_name}
                        />
                    </div>
                    <div className="button-container">
                        <button className="alert-info-button" style={{backgroundColor: "#d3d3d36b"}} 
                            onClick={handleViewAlertData}>View Alert Data</button>

                        {/* Status dependant buttons */ }
                        {alert.status === 'Investigating' && (
                            <button className="alert-info-button" onClick={handleAssignAlert}>Reassign Alert</button>
                        )}
                        {(alert.status === 'New' || alert.status === 'Active' || alert.status === 'Assigned') ? (
                            <ServiceAcknowledged
                                newStatus='Acknowledged'
                                alertId={alert._id}
                                action='Acknowledge'
                                buildingId={building}
                            />
                        ) : (alert.status === 'Acknowledged') ? (
                            <ServiceAcknowledged
                                newStatus='Investigating'
                                alertId={alert._id}
                                action='Investigate'
                                buildingId={building}
                            />
                        ) : (
                            <button className="alert-info-button" onClick={handleAlertStatus}>Update Status</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default AlertInfoPage;


