import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AlertModal = ({ 
    isModalOpen, 
    closeModal, 
    confirmModal, 
    handleOptionClick, 
    selectedOption, 
    height,
    title, 
    children,
    options 
}) => {
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel={title}
            className="modal"
            style={{
                content: {
                    height: height,
                },
                overlay: {
                    backgroundColor: 'rgb(167 167 167 / 75%)'
                }
            }}
        >
            <h2>{title}</h2>
            {children}
            {options && options.length > 0 && options.map(option => (
                <button 
                    key={option}
                    className={`modal-button ${selectedOption === option ? 'selected' : ''}`} 
                    onClick={() => handleOptionClick(option)}
                >
                    {option}
                </button>
            ))}
            {options && options.length > 0 && (
                <div className='close-cancel'>
                    <button className="modal-button alert-cancel" onClick={closeModal}>Cancel</button>
                    <button className="modal-button alert-confirm" onClick={confirmModal}>Confirm</button>
                </div>
            )}
        </Modal>
    );
};

export default AlertModal;
