import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://mybuildingalerts.com/";


const signin = async (username, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth/signin`, {
            username,
            password
        });
        console.log(response.data);

        return {
            ...response.data,
            loginTimestamp: Date.now()
        };
    } catch (error) {
        console.error('Error signing in:', error);
        throw error;
    }
};

const signup = async (username, name, email, password, permissions) => {
    try {
        const response = await axios.post(`${API_URL}/auth/signup`, {
            username,
            name,
            email,
            password,
            permissions
        });
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error('Error signing in:', error);
        throw error;
    }
};

const Authenticate = { 
    signin,
    signup
};

export default Authenticate;