import React, { useState, useEffect } from 'react';
import './Menu.css';
import AlertService from '../../services/AlertService';
import { getCurrentBuilding, setCurrentBuilding, getUserId } from '../../utils/AuthUtilities';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const BuildingMenu = ({ show }) => {
    const user_id = getUserId();
    const current_building_id = getCurrentBuilding();
    
    const [buildings, setBuildings] = useState([]);
    const [currentBuilding, setCurrentBuildingState] = useState(null);

    useEffect(() => {
        const fetchBuildings = async () => {
            try {
                const buildingData = await AlertService.getBuildingsByUserId(user_id);
                
                if (buildingData && buildingData.buildings) {
                    setBuildings(buildingData.buildings);
                    const currentBuilding = buildingData.buildings.find(building => building._id === current_building_id);
                    setCurrentBuildingState(currentBuilding);
                } else {
                    console.error('Expected buildings array but received:', buildingData);
                }
            } catch (error) {
                console.error('Failed to fetch buildings:', error);
            }
        };

        fetchBuildings();
    }, [user_id, current_building_id]);

    const handleBuildingClick = (building) => {
        setCurrentBuilding(building._id); 
        setCurrentBuildingState(building);
    };

    return (
        <nav className={`building-menu ${show ? 'open' : ''}`}>
            <ul className="menu building-add">
                {currentBuilding && (
                    <>
                        {/* <li className="menu-header">Current Building</li> */}
                        <li className="menu-item current-building">
                            <div className="building-item-header current-header">
                                <div className='current-container'>
                                <button id='building-name'>{currentBuilding.name}</button>
                                <button className='building-address'>{currentBuilding.address}</button>
                                </div>
                                <div>
                                    <CheckCircleIcon></CheckCircleIcon>
                                </div>
                            </div>
                        </li>
                    </>
                )}
                {buildings.length > 0 && <li className="menu-header">All Buildings</li>}
                {buildings.map((building, index) => (
                    building._id !== current_building_id && (
                        <li key={index} className="menu-item" 
                        onClick={() => handleBuildingClick(building)} style={{backgroundColor: 'rgba(204, 218, 197, 0.25)'}}>
                            <div className="building-item-header">
                                <button id='building-name'>{building.name}</button>
                                <button className='building-address'>{building.address}</button>
                            </div>
                        </li>
                    )
                ))}
            </ul>
        </nav>
    );
};

export default BuildingMenu;
