import { getCurrentBuilding } from '../../../utils/AuthUtilities';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { ResourceTabs } from './ResourceTabs';
import ResourcePage from './ResourcePage';
import OccupantPage from './OccupantPage';
import OperationalPage from './OperationalPage';

import '../Alerts/AlertPage.css';
import '../NewHome.css';
import './BenefitTracker.css';

import React, { useEffect, useState } from 'react';

const BenefitTrackerPage = ({ benefitType, title }) => {
    const buildingId = getCurrentBuilding();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    
    // for use with future back end
    // useEffect(() => {
    //     const fetchBenefit = async () => {
    //         try {
             
    //         } catch (error) {
    //             setError('Failed to fetch benefits.');
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
        
    //     fetchBenefit();
    // }, [buildingId]);

    const resourceTabs = [
        { label: 'Electricity', content: <ResourcePage resource='Electricity' /> },
        { label: 'Water', content: <ResourcePage resource='Water' /> },
        { label: 'Gas', content: <ResourcePage resource='Natural Gas' /> },
        { label: 'Capital', content: <ResourcePage resource='Capital Spend' /> },
    ];

    const occupantTabs = [
        { label: 'Air Quality', content: <OccupantPage type='Air Quality' /> },
        { label: 'Thermal Comfort', content: <OccupantPage type='Thermal Comfort' /> },
        { label: 'Service Time', content: <OccupantPage type='Service Time' /> },
        { label: 'Survey', content: <OccupantPage type='Survey' /> },
    ];

    const operationalTabs = [
        { label: 'Service & Maintenance', content: <OperationalPage type='Service' /> },
        { label: 'Insurance', content: <OperationalPage type='Insurance' /> },
        { label: 'Admin', content: <OperationalPage type='Admin' /> },
        { label: 'Tenants', content: <OperationalPage type='Tenants' /> },
        { label: 'Emergency Service', content: <OperationalPage type='Emergency' /> },
    ];

    const renderTabs = () => {
        switch (benefitType) {
            case 'Resource Effectiveness':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Yearly Consumption</h2>
                        </div>
                        <ResourceTabs tabs={resourceTabs} padding="8px 16px" />
                    </>
                );
            case 'Occupant Satisfaction':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Building Management Benchmarks</h2>
                        </div>
                        <ResourceTabs tabs={occupantTabs} padding="8px 16px" />
                    </>
                );
            case 'Operational Efficiency':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Operational Efficiency Goals</h2>
                        </div>
                        <ResourceTabs tabs={operationalTabs} padding="8px 16px" />
                    </>
                );
            default:
                return null;
        }
    };


    return (
        <div className='BenefitPage'>
            <div className='benefit-tracker-section'>
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)'}}
                            />
                        </IconButton>
                            <h2>{title}</h2>
                            <div className="placeholder" style={{width: '40px', backgroundColor: 'transparent'}}></div>
                        </div>
                </div>
                <div className='benefit-container'>
                    {/* New Component starts here */}
                        {renderTabs()}
                </div>
            </div>
        </div>

  );

};

export default BenefitTrackerPage;
