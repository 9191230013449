import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../MainDash/Alerts/AlertInfoPage.css';
import '../MainDash/Alerts/AlertPage.css';
import '../MainDash/NewHome.css';
import '../MainDash/Alerts/AssignAlert.css';
import Attach from '../../images/attach.png';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AlertModal from '../MainDash/Alerts/AlertModal';

const Feedback = () => {
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [optionalPhoto, setOptionalPhoto] = useState(null);
    const [optionalPhotoName, setOptionalPhotoName] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [contactPreference, setContactPreference] = useState('');
    const [email, setEmail] = useState('');

    const handleFileChange = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const file = files[0];
            setOptionalPhoto(file);
            setOptionalPhotoName(file.name);
        }
    };

    const handleFeedbackTypeChange = (event) => {
        setFeedbackType(event.target.value);
    };

    const handleContactPreferenceChange = (event) => {
        setContactPreference(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleButtonClick = () => {
        const feedback = {
            description,
            feedbackType,
            contactPreference,
            email: contactPreference === 'yes' ? email : null,
            optionalPhotoName,
            optionalPhoto,
        };

        console.log(feedback);
        
        // Future API call logic
        // await uploadFeedback(feedback);

        setIsModalOpen(true);
    };

    return (
        <>
            <div className='FeedbackPage'>
                <div className='alerts-section'>
                    <div className="alerts-header">
                        <div className="title-container">
                            <IconButton onClick={() => history.push('/Home')}>
                                <ArrowBackIosNewIcon
                                    className="z-0"
                                    style={{ color: 'rgb(14, 81, 46)' }}
                                />
                            </IconButton>
                            <h2>Feedback</h2>
                            <div className="placeholder" style={{ width: '40px', backgroundColor: 'transparent' }}></div>
                        </div>
                    </div>
                    <div className='feedback-content'style={{gap: '16px'}}>
                        <div style={{width: '100%'}}>
                            <h2 className='input-header'>Please provide details:</h2>
                            <textarea
                                type="text"
                                placeholder="Type your feedback"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <label htmlFor="photo-upload" className='photo-label' style={{fontSize: '16px'}}>
                                <img
                                    src={Attach}
                                    alt="upload icon"
                                    style={{ height: '20px', marginLeft: '10px' }}
                                />
                                {optionalPhotoName ? optionalPhotoName : 'Attach a screenshot/photo (optional)'}
                                <input
                                    type="file"
                                    id="photo-upload"
                                    accept="image/*"
                                    style={{ padding: '5px', marginTop: '5px' }}
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                       
                        <div style={{width: '100%'}}>
                            <h2 className='input-header'>What type of feedback?</h2>
                            <div className="feedback-type">
                                <label>
                                    <input
                                        type="radio"
                                        value="Bug Found"
                                        checked={feedbackType === 'Bug Found'}
                                        onChange={handleFeedbackTypeChange}
                                    />
                                    Bug Found
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Enhancement"
                                        checked={feedbackType === 'Enhancement'}
                                        onChange={handleFeedbackTypeChange}
                                    />
                                    Enhancement
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Criticism"
                                        checked={feedbackType === 'Criticism'}
                                        onChange={handleFeedbackTypeChange}
                                    />
                                    Criticism
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Praise"
                                        checked={feedbackType === 'Praise'}
                                        onChange={handleFeedbackTypeChange}
                                    />
                                    Praise
                                </label>
                            </div>
                        </div>

                        <div style={{width: '100%'}}>
                            <h2 className='input-header'>Would you like to be contacted?</h2>
                            <div className="contact-preference">
                                <label>
                                    <input
                                        type="radio"
                                        value="yes"
                                        checked={contactPreference === 'yes'}
                                        onChange={handleContactPreferenceChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="no"
                                        checked={contactPreference === 'no'}
                                        onChange={handleContactPreferenceChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        {contactPreference === 'yes' && (
                            <div style={{width: '100%'}}>
                                <h2 className='input-header'>Enter your email address</h2>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        )}

                        <div className='alert-button-container'>
                            <button className='alert-info-button' 
                                onClick={handleButtonClick}
                                style={{padding: '0.5em 1.25em', fontSize: '18px', marginBottom: '20px'}}>
                                Submit Feedback
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <AlertModal
                isModalOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                confirmModal={() => history.push('/Home')}
                handleOptionClick={() => { }}
                height="200px"
                title="Feedback Sent!"
                options={[]}
            >
                <button className="modal-button alert-confirm" onClick={() => history.push('/Home')}>Return to Home</button>
            </AlertModal>
        </>
    );
};

export default Feedback;
