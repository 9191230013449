import React, { useState, useEffect } from 'react';
import ProgBar from './ProgBar';
import { BenefitGraph } from './BenefitGraph';
import { OccupantGraph } from './OccupantGraph';
import KeyCard from './KeyCard';
import BenefitStatusCard from '../BenefitStatusCard';

const OccupantPage = ({ type }) => {
    const [benefitStatus, setBenefitStatus] = useState('');
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [titleColor, setColor] = useState('');
    const [value, setValue] = useState('');
    const [goal, setGoal] = useState('');

    // function for testing/product display - currently not connected to back end
    function getBenefitStatus(type) {
        let status;
        let dataValue;
        let widthValue;
        let headerTitle;
        let subHeaderTitle;
        let color;
        let improveGoal;
        let onScreen = true;

        if (type === 'Air Quality') {
            status = 'Achieving Goals';
            widthValue = '60%';
            dataValue = '95%';
            headerTitle = 'Daily Average Air Quality';
            subHeaderTitle = 'Measured during Occupied Hours';
            color = 'rgb(41, 98, 0)';
            improveGoal = '95%+ Daily Average';
            onScreen = false;
        } else if (type === 'Thermal Comfort') {
            status = 'Action Needed';
            dataValue = '74%';
            headerTitle = 'Daily Average Thermal Comfort';
            subHeaderTitle = 'Measured during Occupied Hours';
            color = '#00000099';
            improveGoal = '95%+ Daily Average';
            widthValue = '74%';
        } else if (type === 'Service Time') {
            status = 'Achieving Goals';
            dataValue = '8 Hour';
            widthValue = '60%';
            headerTitle = 'Average Service Time';
            subHeaderTitle = 'From Start of Service to Resolution';
            color = 'rgb(41, 98, 0)';
            improveGoal = '50% Faster to Resolution';
            onScreen = false;
        } else {
            status = 'Adapt Goals';
            dataValue = '2';
            headerTitle = 'Quarterly Survey Score';
            subHeaderTitle = 'For Q4';
            color = '#00000099'
            improveGoal = '20% Increase in Score';
            widthValue = '67%';
        }

        setBenefitStatus(status);
        setWidth(widthValue);
        setTitle(headerTitle);
        setSubTitle(subHeaderTitle);
        setColor(color);
        setOnBenefitScreen(onScreen);
        setValue(dataValue);
        setGoal(improveGoal);
    }

    // useEffect to update benefit status and width based on resource
    useEffect(() => {
        getBenefitStatus(type);
    }, [type]);

    return (
        <>
            <div className='savings-header'><span style={{color: titleColor}}>{value}</span> {title}</div>
            <div className='savings-sub-header'>{subTitle}</div>

            <OccupantGraph type={type}/>
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus} 
                    width={width} 
                    onBenefitScreen={onBenefitScreen}
                />
                <div className='savings-data'>
                    <div className='occupant-savings'>Improvement Goal: <span>{goal}</span></div>
                </div>
            </div>
        </>
    );
};

export default OccupantPage;
