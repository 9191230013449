import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Attach from '../../../images/attach.png'
import './AssignAlert.css'

import AlertModal from './AlertModal';
import AlertService from '../../../services/AlertService';

const AssignContent = ({selectedOption, alertId, building, suite, user_id, assignedPersonnel}) => {
    const [text, setText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleButtonClick = () => {
        if (text.trim() === '') {
            alert('Please enter a description before assigning.');
        } else {
            confirmAssign();
        }
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const confirmAssign = async () => {
        try {
            let updatedAssignedPersonnel = [...assignedPersonnel];

            if (selectedOption === 'Tenant') {
                if (!updatedAssignedPersonnel.includes(suite.tenant)) {
                    updatedAssignedPersonnel = [...updatedAssignedPersonnel, suite.tenant];
                }
            } else if (selectedOption === 'Service Contractor') {
                if (building.serviceContractor && building.serviceContractor.length > 0 &&  
                    !updatedAssignedPersonnel.includes(building.serviceContractor[0])) {
                    updatedAssignedPersonnel = [...updatedAssignedPersonnel, building.serviceContractor[0]];
                }
            } else if (selectedOption === 'Building Team') {
                if (!updatedAssignedPersonnel.includes(user_id)) {
                    updatedAssignedPersonnel = [...updatedAssignedPersonnel, user_id];
                }

                // For future use if property manager array is populated, currently just uses user_id for owner
                    // if (building.propertyManager && building.propertyManager.length > 0 && 
                    //     !updatedAssignedPersonnel.includes(building.propertyManager[0])) {
                                // updatedAssignedPersonnel = [...updatedAssignedPersonnel, building.propertyManager[0]];
                    // }
            }   
        
            await AlertService.updateAlertAssignedPersonnel(alertId, updatedAssignedPersonnel);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error updating alert:', error);
        }
    };
    

    return (
        <>
        <div className="tenant-content">
            <h2 className='text-header'>Describe service required:</h2>
            <textarea type="text" placeholder="Type your report" onChange={handleTextChange} />
            <label htmlFor="photo-upload" className='photo-label'>
                <img 
                    src={Attach}
                    alt="upload icon" 
                    style={{ height: '20px', marginLeft: '10px' }} 
                />
                Attach a photo (optional)
                <input 
                    type="file" 
                    id="photo-upload" 
                    accept="image/*" 
                    style={{ padding: '5px', marginTop: '5px' }}
                />
            </label>
            <div className='alert-button-container'>
                <button className='alert-info-button' 
                style={{padding: '0.65em 0.5em'}}
                    onClick={handleButtonClick}>
                    Save Changes
                </button>
            </div>
        </div>

        <AlertModal 
            isModalOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)} 
            confirmModal={handleGoBack} 
            handleOptionClick={() => {}} 
            selectedOption={selectedOption} 
            height="200px"
            title="Success!"
            options={[]}
        >
            <p>This alert has been assigned to the {selectedOption}.</p>
            <button className="modal-button alert-confirm" 
                onClick={handleGoBack}>Return to Alert Description</button>
        </AlertModal>
        </>
    );
};

export default AssignContent


