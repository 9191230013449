import React, { useEffect, useState } from 'react';
import './AlertInfoPage.css';
import '../NewHome.css';
import './AssignAlert.css';
import ServiceNeeded from './ServiceNeeded';
import ServiceScheduled from './ServiceScheduled';
import AlertService from '../../../services/AlertService';

const AlertStatus = ({currentStatus, alertId, serviceId}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const statusOptions = {
            'New': ['Acknowledged'],
            'Active': ['Acknowledged'],
            'Assigned': ['Acknowledged'],
            'Acknowledged': ['Investigating'],
            'Investigating': ['Service Needed', 'Resolved'],
            'Service Needed': ['Service Scheduled'],
            'Service Scheduled': ['Service Started'],
            'Service Started': ['Resolved'],
            'Resolved': []
        };

        setOptions(statusOptions[currentStatus] || []);
    }, [currentStatus]);

    const handleOptionChange = (event) => {
        const newStatus = event.target.value;
        setSelectedOption(newStatus);
        // onUpdateStatus(newStatus);
    };

    const updateStatus = async () => {
        try {
            await AlertService.updateAlertStatus(alertId, selectedOption);
        } catch (error) {
            console.error('Error updating alert:', error);
        }
    };


    return (
        <div className="alert-status-update">
            <hr style={{margin: '0 0 1.5em 0'}}></hr>
            <div className="dropdown-container">
                <label htmlFor="alert-status-dropdown" style={{fontWeight: 'bold', margin: '0'}}>Change Alert Status to:</label>
                <select
                    id="alert-status-dropdown"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="assign-alert-dropdown"
                >
                    <option value="">Select an option</option>
                    {options.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>

        {selectedOption && (selectedOption === 'Acknowledged' || selectedOption === 'Investigating') && (
                <div className="selected-option-content">
                    <div className='alert-button-container'>
                        <button className='alert-info-button' onClick={updateStatus}>
                            Change to {selectedOption}
                        </button>
                    </div>
                </div>
        )}
        {selectedOption && 
            selectedOption === 'Service Needed' && (
                <ServiceNeeded
                    serviceTypes='types'
                    height='450px'
                    title='Describe Service Required (optional)'
                    resolved={false}
                    selectedOption={selectedOption}
                    alertId
                    updateStatus={updateStatus}
                    serviceId={serviceId}
                    serviceType='serviceRequired'
                />
        )}
        {selectedOption && 
            selectedOption === 'Service Scheduled' && (
                <ServiceScheduled
                    selectedOption={selectedOption}
                    alertId
                    updateStatus={updateStatus}
                />
        )}
        {selectedOption && 
            selectedOption === 'Service Started' && (
                <ServiceNeeded
                    serviceTypes={null}
                    height='295px'
                    title='Describe Service Activity'
                    resolved={false}
                    selectedOption={selectedOption}
                    alertId
                    updateStatus={updateStatus}
                    serviceId={serviceId}
                    serviceType='serviceActivity'
                />
        )}
        {selectedOption && 
            selectedOption === 'Resolved' && (
                <ServiceNeeded
                    serviceTypes={null}
                    height='375px'
                    title='Describe Service Performed'
                    resolved
                    selectedOption={selectedOption}
                    alertId
                    updateStatus={updateStatus}
                    serviceId={serviceId}
                    serviceType='servicePerformed'
                />
        )}
        </div>
    );
};
export default AlertStatus