export const GoalData = {
    resource: [
      {
        description: "Electricity Consumption",
        currentGoal: "5",
        previousYear: "10,000 kWh"
      },
      {
        description: "Water Consumption",
        currentGoal: "10",
        previousYear: "5,000 Gallons"
      },
      {
        description: "Natural Gas Consumption",
        currentGoal: "8",
        previousYear: "5,000 Therms"
      },
      {
        description: "Capital Expenditures",
        currentGoal: "15",
        previousYear: "$2,000"
      }
    ],
    operational: [
      {
        description: "Service & Maintenance Hours",
        currentGoal: "12",
        previousYear: "435 Hours"
      },
      {
        description: "Insurance Costs",
        currentGoal: "5",
        previousYear: "$45k"
      },
      {
        description: "Administrative Hours",
        currentGoal: "10",
        previousYear: "300 Hours"
      },
      {
        description: "Tenant Turnover Hours",
        currentGoal: "7",
        previousYear: "300 Hours"
      },
      {
        description: "Emergency Service Hours",
        currentGoal: "9",
        previousYear: "300 Hours"
      }
    ],
    satisfaction: [
      {
        description: "Maintain Good Indoor Air Quality",
        currentGoal: "95",
        previousYear: "", 
      },
      {
        description: "Maintain Good Indoor Thermal Comfort",
        currentGoal: "95",
        previousYear: ""
      },
      {
        description: "Reduce Average Service Resolution Time",
        currentGoal: "8",
        previousYear: "10 Hours"
      }
      // {
      //   description: "Achieve an Average of 4+ on the Quarterly Occupant Satisfaction Survey",
      //   currentGoal: "4",
      //   previousYear: "(scale of 1-5, 1 is Poor and 5 is Excellent)"
      // }
    ]
  };
  