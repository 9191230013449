import { React, useState } from "react";
import "./Dashboard.css";
import Sidebar from "../Depreciated/Sidebar/Sidebar";
// import MainDash from "../MainDash/MainDash";
import { Route, Switch, useHistory } from "react-router-dom";
//import Contractors from "./Contractors";
//import Profile from "./Profile";
//import PersonalInfo from "./PersonalInfo";
//import Settings from "./Settings";
// import Tenants from "./Tenants";
//import RequestHistory from "./RequestHistory";
//import AlertHistory from "./AlertHistory";
//import TenantList from "./TenantList";
//import ContractorList from "./ContractorList";
import NewHome from "../MainDash/NewHome";
//import AlertsManagement from "../AlertManagement/AlertsManagement";
// import Sensors from "../MainDash/Sensors";
//import UtilityBill from "../Depreciated/UtilityBill";
//import Notifications from "./Notifications";
//import CreateRequest from "../RequestManagement/CreateRequest";
//import AlertManagement from "../AlertManagement/AlertManagement";
//import RequestManagement from "../RequestManagement/RequestManagement";
//import Requests from "../RequestManagement/Requests";
//import CompletedAlerts from "../AlertManagement/CompletedAlerts";
//import CompletedRequests from "../RequestManagement/CompletedRequests";
// import AlertReview from "../MainDash/AlertReview";
import HealthSafetyAlerts from "../MainDash/Alerts/HealthSafetyAlerts";
import ResourceEfficiency from "../MainDash/Alerts/ResourceEfficiency";
import ServiceCheck from "../MainDash/Alerts/ServiceCheck";
import AlertInfoPage from "../MainDash/Alerts/AlertInfoPage";
import AlertDetailsPage from "../MainDash/Alerts/AlertDetailsPage";
import ResourceEffectiveness from '../MainDash/BenefitTracker/ResourceEffectiveness';
import OccupantSatisfaction from "../MainDash/BenefitTracker/OccupantSatisfaction";
import OperationalEfficiency from "../MainDash/BenefitTracker/OperationalEfficiency";
import BenefitGoals from "../MainDash/BenefitTracker/BenefitGoals";
import Feedback from "../Menu/Feedback";
import SubmitPage from "../Menu/Submit/SubmitPage";
import Profile from "../Menu/Manage/Profile";
import BuildingInfo from "../Menu/Manage/BuildingInfo";
import Tenants from "../Menu/Manage/Tenants";
import Contractors from "../Menu/Manage/Contractors";
import Buildings from "../Menu/Manage/Buildings";
import DataPage from "../Menu/Manage/DataPage";
import Menu from "../Menu/Menu";
import BuildingMenu from "../Menu/BuildingMenu";
import NoBuildings from "../Login/NoBuildings";
import Header from "../Header";

//import { checkCookieExpiration } from "../AuthUtilities";
// import Test from "../MainDash/Test";

function Dashboard() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isBuildingMenuOpen, setBuildingMenuOpen] = useState(false);
  // const [title, setTitle] = useState("Home");
  //const history = useHistory();

  const toggleMenu = () => {
    if (isBuildingMenuOpen) {
      setBuildingMenuOpen(false);
    }
    setShowMenu(!showMenu);
  };

  const toggleBuildingMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    }
    setBuildingMenuOpen(!isBuildingMenuOpen);
  };

  // useEffect(() => {
  //   const isTokenExpired = checkCookieExpiration();
  //   if (isTokenExpired) {
  //     history.push("/"); // Redirect to login or home
  //   }
  // }, [history]);

  return (
    <div>
       <div className="min-h-screen">
       <Header 
          onMenuClick={toggleMenu} 
          onBuildingClick={toggleBuildingMenu} 
          isMenuOpen={showMenu} 
          isBuildingMenuOpen={isBuildingMenuOpen} 
        />
       <Menu show={showMenu} setter={setShowMenu} onClose={() => setShowMenu(false)}/>
       <BuildingMenu show={isBuildingMenuOpen} setter={setBuildingMenuOpen}/>
        <div className="parent-container flex">
          <div className="md:hidden">
          </div>
          <Sidebar className='sidebar' show={showSidebar} setter={setShowSidebar} />
          <div className="sidebar-content" style={{overflowY: 'scroll', height: '92dvh'}}>
            <Switch>
              <Route path="/Home" component={NewHome} />
              {/* <Route path="/Sensors" component={Sensors} /> */}
              {/* <Route path="/MainDash" component={MainDash} /> */}
              {/* <Route path="/contractors" component={Contractors} /> */}
              {/* <Route path="/requesthistory" component={RequestHistory} /> */}
              {/* <Route path="/alerthistory" component={AlertHistory} /> */}
              {/* <Route path="/tenants" component={Tenants} /> */}
              {/* <Route path="/profile" component={Profile} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/personal" component={PersonalInfo} /> */}
              {/* <Route path="/tenantlist" component={TenantList} /> */}
              {/* <Route path="/contractorlist" component={ContractorList} /> */}
              {/* <Route path="/settings" component={Settings} /> */}
              {/* <Route path="/utility" component={UtilityBill} /> */}
              {/* <Route path="/test" component={Test} /> */}
              <Route path="/HealthSafety" component={HealthSafetyAlerts} />
              <Route path="/ResourceEfficiency" component={ResourceEfficiency} />
              <Route path="/ServiceCheck" component={ServiceCheck} />
              <Route exact path="/AlertInfo/:id" component={AlertInfoPage} />
              <Route path="/alert/:id/details" component={AlertDetailsPage} />
              <Route path="/NoBuildings" component={NoBuildings} />
              <Route path="/ResourceEffectiveness" component={ResourceEffectiveness} />
              <Route path="/OccupantSatisfaction" component={OccupantSatisfaction} />
              <Route path="/OperationalEfficiency" component={OperationalEfficiency} />
              <Route path="/BenefitGoals" component={BenefitGoals} />
              <Route path="/Feedback" component={Feedback} />
              <Route path="/SubmitPage" component={SubmitPage} />
              <Route path="/Profile" component={Profile} />
              <Route path="/BuildingInfo" component={BuildingInfo} />
              <Route path="/Tenants" component={Tenants} />
              <Route path="/Contractors" component={Contractors} />
              <Route path="/Buildings" component={Buildings} />
              <Route path="/DataPage/:type/:id" component={DataPage} />
              

              {/* Contractor */}
              {/* <Route path="/alerts/manage" component={AlertManagement} /> */}
              {/* <Route path="/alerts/review/:alertId" component={AlertReview} /> */}
              {/* <Route path="/alerts/new" component={AlertsManagement}
                render={(props) => <AlertsManagement {...props} inProgress={true} />}
              /> */}
              {/* <Route path="/alerts/in-progress" component={AlertsManagement}
                render={(props) => <AlertsManagement {...props} inProgress={true} />}
              /> */}
              {/* <Route path="/alerts/completed" component={CompletedAlerts} />
              <Route path="/requests/manage" component={RequestManagement} />
              <Route path="/requests/completed" component={CompletedRequests} />
              <Route path="/requests/new" component={CreateRequest} />
              <Route path="/requests/in-progress" component={Requests}
                render={(props) => <Requests {...props} inProgress={true} />}
              />
              <Route path="/requests/requests" component={Requests}
                render={(props) => <Requests {...props} inProgress={false} />}
              /> */}
            </Switch>
          </div> 
        </div>
      </div> 
    </div>
  );
}

export default Dashboard;
