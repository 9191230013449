import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export function AddButton() {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 }, zIndex: 10 }}>
      <Fab size='medium' aria-label="add" 
        sx={{ backgroundColor: '#CCDAC5', '&:hover': { backgroundColor: '#bac3b6'} }}>
        <AddIcon sx={{ color: 'rgb(14, 81, 46)' }}/>
      </Fab>
    </Box>
  );
}
