import React, { useState } from 'react';
import './Menu.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from "@mui/material";
import menuData from './MenuData';
import MenuItem from './MenuItem';
import { useHistory } from "react-router-dom";

const Menu = ({ show, onClose }) => {
    const [path, setPath] = useState([]);
    const history = useHistory();
  
    const handleLogout = () => {
        localStorage.setItem('isLoggedIn', 'false');
        history.push("/");
    };
    
    const handleItemClick = (item) => {
        if (item.subItems && item.subItems.length > 0) {
            setPath([...path, item]);
        } else if (item.id === 'logout') {
            handleLogout();
        } else {
            history.push(item.link);
            setPath([]);
            onClose();
        }
    };
  
    const handleBackClick = () => {
        setPath(path.slice(0, -1));
    };
  
    const currentItems = path.length === 0 ? menuData : path[path.length - 1].subItems;
    const currentCategory = path.length > 0 ? path[path.length - 1].title : '';
  
    return (
        <nav className={`hamburger-menu ${show ? 'open' : ''}`}>
            {path.length > 0 && (
                <div className="back-button-container">
                    <IconButton onClick={handleBackClick} className="back-button">
                        <ArrowBackIosNewIcon fontSize="small" color='#5C5B49' style={{ marginRight: '15px' }} />
                    </IconButton>
                    <span className="current-category">{currentCategory}</span>
                </div>
            )}
            <ul className={`menu ${show ? 'open' : ''}`}>
                {currentItems.map(item => (
                    <MenuItem
                        key={item.id}
                        item={item}
                        onClick={() => handleItemClick(item)}
                    />
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
