// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import Routes from './components/Routes';
// import { Provider } from 'react-redux';
// import store from './store/store';
// import swDev from './swDev';
// import Dashboard from './components/Dashboard/Dashboard';
// import Signin from './components/Signin';
// import Signup from './components/Signup';
// import ResetPassword from './components/ResetPassword';
// import Guest from './components/Guest';
// // comment for test commit only

// ReactDOM.render(
//   <Provider store={store}>
//     <Router>
//       <Switch>
//         <Route exact={true} path="/" component={Signin} />
//         <Route exact={true} path="/signup" component={Signup} />
//         <Route exact={true} path="/reset" component={ResetPassword} />
//         <Route exact={true} path="/guest" component={Guest} />
//         <Dashboard/>
//         <Routes/>
//       </Switch>
//     </Router>
//   </Provider>
//   ,
//   document.getElementById('root')
// );

// swDev();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
// import Routes from './components/Routes';
// import { Provider } from 'react-redux';
// import store from './store/store';
// import swDev from './swDev';
// import Dashboard from './components/Dashboard/Dashboard';
// import Signin from './components/Signin';
// import Signup from './components/Signup';
// import ResetPassword from './components/ResetPassword';
// import Guest from './components/Guest';


// const history = useHistory();

// ReactDOM.render(

//   <Provider store={store}>
//     <Router>
//       <Switch>
//         <Route exact={true} path="/" render={() => (
//           localStorage.getItem('isLoggedIn') === 'true' ? (
//             history.push("/Home")
//           ) : (
//             <Signin/>
//           )
//         )} />
//         <Route exact={true} path="/signup" component={Signup} />
//         <Route exact={true} path="/reset" component={ResetPassword} />
//         <Route exact={true} path="/guest" component={Guest} />
//         <Route path="/dashboard" component={Dashboard}/>
//         <Routes/>
//       </Switch>
//     </Router>
//   </Provider>
//   ,
//   document.getElementById('root')
// );

// swDev();
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
//import Routes from './components/Routes';
import { Provider } from 'react-redux';
import store from './store/store';
//import swDev from './swDev.js';
import Dashboard from './components/Dashboard/Dashboard';
import Signin from './components/Login/Signin';
import Signup from './components/Login/Signup';
import ResetPassword from './components/Login/ResetPassword';
import Guest from './components/Login/Guest';
import Login from './components/Login/Login';
import { AppProvider } from './AppContext';
//import { checkCookieExpiration } from './components/AuthUtilities';

//import OfflineBanner from './components/OfflineBanner';
// import NetworkStatus from './components/NetworkStatus';

// New component for handling online/offline alerts
// const NetworkStatus = () => {
//   React.useEffect(() => {
//     const handleOnline = () => {
//       alert('You are online now!');
//     };

//     const handleOffline = () => {
//       alert('You are offline!');
//     };

//     window.addEventListener('online', handleOnline);
//     window.addEventListener('offline', handleOffline);

//     // Cleanup event listeners on component unmount
//     return () => {
//       window.removeEventListener('online', handleOnline);
//       window.removeEventListener('offline', handleOffline);
//     };
//   }, []);

//   return null; // This component does not render anything
// };

// New component
const HomeRedirect = () => {
  const history = useHistory();

  // console.log('here');
  // useEffect(() => {
  //   const isTokenExpired = checkCookieExpiration();
  //   if (isTokenExpired) {
  //     window.alert('Your session expired. Please login again.');
  //     history.push("/"); 
  //   } else {
  //     console.log('ere');
  //   }
  // }, [history]);

  if (localStorage.getItem('isLoggedIn') === 'true') {
    const resp = localStorage.getItem('loginResponse');
    const perm = JSON.parse(resp)?.permissions;
    if (perm === "owner") {
      history.push("/Home");
    } else {
      history.push("/requests/new")
    }
    return null;
  } else {
    console.log("This is signin");
    return <Signin />;
  }
}

ReactDOM.render(
  <AppProvider>
    <Provider store={store}>
    <Router>
    {/* <NetworkStatus /> */}
      <Switch>
        <Route exact={true} path="/" component={HomeRedirect} />
        <Route exact={true} path="/login" component={Login}/>
        <Route exact={true} path="/signup" component={Signup} />
        <Route exact={true} path="/reset" component={ResetPassword} />
        <Route exact={true} path="/guest" component={Guest} />
        <Route path="/dashboard" component={Dashboard}/>
        {<Dashboard /> /*important line */}
        {/* <Routes /> */}
      </Switch>
    </Router>
    </Provider>
  </AppProvider>
  ,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then((registration) => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch((error) => {
        console.log('ServiceWorker registration failed: ', error);
      });
  });
}

//swDev();