// RequestCard.jsx
import React from "react";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Colors from "../../../constants/Colors";
import { IconButton } from "@mui/material";
import { Button } from "@mui/material";
import { colors, camelCase } from "../../../constants/constants";
import { useHistory } from "react-router-dom";
import './NewAlertCard.css'

const NewAlertCard = ({ severity, description, alertObj }) => {
    // let textColor = colors.primaryColor;
    let backgroundColor = colors.primaryColor;
    const history = useHistory();
    
    switch (severity) {
        case 'High':
            // textColor = colors.alertText;
            backgroundColor = colors.alertBackground;
            break;
        case 'Low':
            //textColor = colors.lowText;
            backgroundColor = colors.lowBackground;
            break;
        case 'RE-CHECK':
            //textColor = colors.lowText;
            backgroundColor = colors.recheckBackground;
            break;
        default:
            //textColor = colors.green;
            backgroundColor = colors.greenBackground;
            break;
    }

    return (
        <div className="card-box">
            <div className='flex flex-col'>
                {/* Add a red circle */}
                <div className="flex">
                    <Button
                        className='card-button font-bold px-3 py-1'
                        style={{
                            textTransform: 'none',
                            borderRadius: '10px',
                            backgroundColor: backgroundColor,
                            color: 'white',
                            fontWeight: 'bold',
                            width: '7em',
                            fontSize: '16px'
                        }}
                    >
                        {camelCase(severity)}
                    </Button>
                    <div className="w-8" />
                    <div className='grow flex items-center text-xl text-primaryColor description-text'>{description}</div>
                </div>
            </div>
            <div className='flex items-center'>
                <IconButton onClick={() => history.push(`/AlertInfo/${alertObj._id}`)}>
                    <ArrowForwardIos
                        className="z-0"
                        style={{ color: Colors.primary }}
                    />
                </IconButton>
            </div>
        </div>
    );
}
export default NewAlertCard;

