import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://mybuildingalerts.com/";


const getAlertsByBuildingId = async (buildingId, device = false) => {
    try {
        const response = await axios.get(`${API_URL}/alerts/buildingalerts/${buildingId}`, {
            withCredentials: true, // To include the access_token cookie
            params: { device }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch alerts", error);
        throw error;
    }
};

const getAlertById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/alerts/${id}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch alert", error);
        throw error;
    }
};

const getBuildingById = async (buildingId) => {
    try {
        const response = await axios.get(`${API_URL}/buildings/${buildingId}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch alert", error);
        throw error;
    }
};

const getTenantsByBuildingId = async (buildingId) => {
    try {
        const response = await axios.get(`${API_URL}/tenants/building/${buildingId}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch tenants", error);
        throw error;
    }
};

const getBuildingsByUserId = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/buildings/user/${userId}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch alert", error);
        throw error;
    }
};

const getSuiteById = async (suiteId) => {
    try {
        const response = await axios.get(`${API_URL}/suites/${suiteId}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch suite", error);
        throw error;
    }
};

const getDevicesByIds = async (deviceIds) => {
    try {
        const response = await axios.get(`${API_URL}/devices/sensor?deviceIds=${deviceIds}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch alert", error);
        throw error;
    }
};

const updateAlertAssignedPersonnel = async (alertId, assignedPersonnel) => {
    try {
        const response = await axios.put(`${API_URL}/alerts/${alertId}`, {
            assignedPersonnel: assignedPersonnel
        }, {
            withCredentials: true 
        });
        return response.data;
    } catch (error) {
        console.error("Failed to update alert", error);
        throw error;
    }
};

const updateAlertStatus = async (alertId, alertStatus) => {
    try {
        const response = await axios.put(`${API_URL}/alerts/${alertId}`, {
            status: alertStatus
        }, {
            withCredentials: true 
        });
        return response.data;
    } catch (error) {
        console.error("Failed to update alert", error);
        throw error;
    }
};

const getUserById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/users/${id}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user", error);
        throw error;
    }
};

const getTenantById = async (tenantId) => {
    try {
        const response = await axios.get(`${API_URL}/tenants/${tenantId}`, {
            withCredentials: true // To include the access_token cookie
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch tenant", error);
        throw error;
    }
};

const AlertService = { 
    getAlertsByBuildingId, 
    getAlertById, 
    getBuildingById, 
    getTenantsByBuildingId,
    getBuildingsByUserId,
    getSuiteById,
    updateAlertAssignedPersonnel, 
    updateAlertStatus, 
    getDevicesByIds,
    getUserById,
    getTenantById,
};

export default AlertService;