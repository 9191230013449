// import { Button } from "@material-ui/core";
//import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import DataFetcher from "../services/DataFetcher";
//import Network from "../services/Network";
//import StorageService from "../services/StorageService";
import Authenticate from "../../services/Authenticate";
import AlertModal from "./../MainDash/Alerts/AlertModal";
import "./Signup.css";


function Signup() {
  const [email, setemail] = useState("");
  const [username, setUsername] = useState("");
  const [name, setname] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [password, setpassword] = useState("");
  const [permissions, setPermissions] = useState("");
  //const [serverMsg, setServerMsg] = useState(null);
  //const [submitted, setSubmitted] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  // const storageService = new StorageService();
  // const network = new Network({}, {
  //   username: username,
  //   name: name,
  //   email: email,
  //   mobilenumber : mobilenumber,
  //   password: password,
  //   permissions: permissions
  // });

  const handleGoBack = () => {
    history.goBack();
  };

  //const dataFetcher = new DataFetcher(network, storageService);

  const handleSubmit = async (e) => {
    try {
        e.preventDefault();
        //setSubmitted(true);
        const response = await Authenticate.signup(username, name, email, password, permissions);
        console.log(response);
        setIsModalOpen(true);
    } catch (error) {
      console.error('Error creating account:', error);
    }
  
  };


  return (
    <>
    <div className="signup">
    {/* {serverMsg && serverMsg.success && 
        <Alert severity="success">
          <AlertTitle>Account created successfully!</AlertTitle>
          {serverMsg.message_user}
        </Alert>
      } */}

      <div className="signup__form">
        <div className="signup__box">
          <h5 className="app_name">Join myBuilding Alerts</h5>
          <p className="tag_line">
          Be alerted to conditions affecting Health & Safety, 
          items affecting utility bills, areas requring service, 
          and general notifications affecting your building, and more.
          </p>
          <div className="signup-content">
            <input
              onChange={(e) => setname(e.target.value)}
              type="text"
              placeholder="Full Name"
              value={name}
            />

            <input
              onChange={(e) => setmobilenumber(e.target.value)}
              type="text"
              placeholder="Mobile Number"
              value={mobilenumber}
            />


            <input
              onChange={(e) => setemail(e.target.value)}
              type="email"
              placeholder="Email Address"
              value={email}
            />

            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="Username"
              value={username}
            />
            
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setpassword(e.target.value)}
              value={password}
            />

            <select
              value={permissions}
              onChange={(e) => setPermissions(e.target.value)}
            >
              <option value="">Select your role</option>
              <option value="owner">Owner</option>
              <option value="tenant">Tenant</option>
              <option value="contractor">Contractor</option>
              <option value="property manager">Property Manager</option>
            </select>

            <button onClick={handleSubmit} className='login-button' style={{margin: '1em 0 0 0'}}>Create Account</button>
          </div>
        </div>
        <button onClick={() => history.push('/')} className="cancel-button">Cancel</button>
      </div>
      {/* <Redirect to="/dashboard" /> */}
    </div>

    <AlertModal 
      isModalOpen={isModalOpen} 
      closeModal={() => setIsModalOpen(false)} 
      confirmModal={handleGoBack} 
      handleOptionClick={() => {}} 
      selectedOption={null} 
      height="200px"
      title="Confirmation Email Sent!"
      options={[]}
      >
      <p>Check your inbox to confirm your email address and then login.</p>
      <button className="modal-button alert-confirm" 
          onClick={handleGoBack}>Go to Login Page</button>
    </AlertModal>
    </>
  );
}

export default Signup;
