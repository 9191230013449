import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './AlertInfoPage.css';
import '../NewHome.css';
import './AssignAlert.css';
import AlertModal from './AlertModal';

const ServiceScheduled = ({selectedOption, alertId, updateStatus}) => {
    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();

    const handleDateChange = (event) => {
        setScheduledDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setScheduledTime(event.target.value);
    };

    const handleButtonClick = () => {
        if (!scheduledDate || !scheduledTime) {
            alert('Please select both a date and a time.');
            return;
        }
        console.log('Schedule Service button clicked', scheduledDate, scheduledTime);
        updateStatus(alertId, selectedOption);
        setIsModalOpen(true);
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <>
        <div className="service-scheduled-content">
            <div className="date-input-container">
                <label htmlFor="schedule-date" style={{ fontWeight: 'bold', margin: '0 0.5em 0 0' }}>Schedule Date:</label>
                <input 
                    type="date" 
                    id="schedule-date" 
                    value={scheduledDate} 
                    onChange={handleDateChange} 
                    className="schedule-date-input no-border-input"
                />
            </div>
            <div className="input-container">
                <label htmlFor="schedule-time" style={{ fontWeight: 'bold', margin: '0 0.5em 0 0' }}>Schedule Time:</label>
                <input 
                    type="time" 
                    id="schedule-time" 
                    value={scheduledTime} 
                    onChange={handleTimeChange} 
                    className="schedule-time-input no-border-input"
                />
            </div>
            <div className='alert-button-container'>
                <button className='alert-info-button' onClick={handleButtonClick}>
                    Schedule Service - {scheduledDate}, {scheduledTime}
                </button>
            </div>
        </div>
        <AlertModal 
            isModalOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)} 
            confirmModal={handleGoBack} 
            handleOptionClick={() => {}} 
            selectedOption={selectedOption} 
            height="200px"
            title="Success!"
            options={[]}
        >
            <p>Status changed to {selectedOption}.</p>
            <button className="modal-button alert-confirm" 
                onClick={handleGoBack}>Return to Alert Description</button>
        </AlertModal>
        </>
    );
};
export default ServiceScheduled