import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export function OpBarChart({type}) {

    const data_1_service = [680, 435];
    const data_2_service = [0, 175];

    const data_1_insurance = [50000, 45000];
    const data_2_insurance = [0, 2000];

    const data_1_admin = [600, 300];
    const data_2_admin = [0, 150];

    const getData = (type) => {
        switch (type) {
            case 'Service':
                return [data_1_service, data_2_service];
            case 'Insurance':
                return [data_1_insurance, data_2_insurance];
            case 'Admin':
                return [data_1_admin, data_2_admin];
            case 'Tenants':
                return [data_1_admin, data_2_admin];
            case 'Emergency':
                return [data_1_admin, data_2_admin];
            default:
                return [[], []];
        }
    };

    const colors = ['#02B2AF', 'white'];
    const [data1, data2] = getData(type);

  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['2023', '2024'],  }]}
      series={[
        { data: data1, stack: 'A', id: 'no-color'},  
        { data: data2, stack: 'A', id: 'color_id'}
      ]}
      width={300}
      height={275}
      margin={{
        left: 50,
        right: 15,
        top: 10,
        bottom: 20,
      }}
      sx={{
        '.MuiBarElement-series-color_id' : {
            stroke: '#02B2AF',
            fill: 'white',
        }
      }}
      colors={colors}
    />
  );
}
