import { getCurrentBuilding } from '../../../utils/AuthUtilities';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { ResourceTabs } from './ResourceTabs';
import GoalPage from './GoalPage';

import '../Alerts/AlertPage.css';
import '../NewHome.css';
import './BenefitTracker.css';

import React, { useEffect, useState } from 'react';

const BenefitGoals = ({ title }) => {
    const buildingId = getCurrentBuilding();
    const history = useHistory();

    const tabs = [
        {
          label: 'Resource',
          content: <GoalPage type='resource' />,
        },
        {
          label: 'Operations',
          content: <GoalPage type='operational' />,
        },
        {
          label: 'Satisfaction',
          content: <GoalPage type='satisfaction' />,
        },
      ];

    return (
        <div className='BenefitPage'>
            <div className='benefit-tracker-section'>
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)'}}
                            />
                        </IconButton>
                            <h2>Benefit Goals</h2>
                            <div className="placeholder" style={{width: '40px', backgroundColor: 'transparent'}}></div>
                        </div>
                </div>
                <div className='benefit-container'>
                <div className='benefit-header-container'>
                        <h2 className='yearly-header'>Annual Reduction Goals</h2>
                    </div>
                    <ResourceTabs tabs={tabs} />
                </div>
            </div>
        </div>

  );

};

export default BenefitGoals;
