import React, { useState, useEffect } from 'react';
import { OpBarChart } from './OperationsBarChart';
import KeyCard from './KeyCard';
import BenefitStatusCard from '../BenefitStatusCard';

const OperationalPage = ({ type }) => {
    const [benefitStatus, setBenefitStatus] = useState('');
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [titleColor, setColor] = useState('');
    const [value, setValue] = useState('');
    const [goal, setGoal] = useState('');

    // function for testing/product display - currently not connected to back end
    function getBenefitStatus(type) {
        let status;
        let widthValue;
        let headerTitle;
        let subHeaderTitle;
        let color;
        let improveGoal;
        let dataValue = ''; // Initialize dataValue
        let onScreen = true;

        switch(type) {
            case 'Service':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Service & Maintenance Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '435';
                improveGoal = '20% Decrease';
                onScreen = false;
                break;
            case 'Insurance':
                status = 'Action Needed';
                widthValue = '74%';
                headerTitle = ' Yearly Insurance Cost';
                subHeaderTitle = '';
                color = '#00000099';
                dataValue = '$45k'; 
                improveGoal = '10% Decrease';
                break;
            case 'Admin':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Admin Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '300'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            case 'Tenants':
                status = 'Adapt Goals';
                widthValue = '67%';
                headerTitle = 'Yearly Tenant Turnover Hours';
                subHeaderTitle = '';
                color = '#00000099';
                dataValue = '300'; 
                improveGoal = '25% Decrease';
                break;
            case 'Emergency':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Emergency Service Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '150'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            default:
                status = 'Adapt Goals';
                widthValue = '67%';
                headerTitle = 'Operational Performance Goals';
                subHeaderTitle = 'Measured during Operational Hours';
                color = '#00000099';
                dataValue = '67%'; // Example value
                improveGoal = '25% Decrease';
        }

        setBenefitStatus(status);
        setWidth(widthValue);
        setTitle(headerTitle);
        setSubTitle(subHeaderTitle);
        setColor(color);
        setValue(dataValue); // Set dataValue
        setGoal(improveGoal);
        setOnBenefitScreen(onScreen);
    }

    // useEffect to update benefit status and width based on resource
    useEffect(() => {
        getBenefitStatus(type);
    }, [type]);

    return (
        <>
            <div className='savings-header'><span style={{color: titleColor}}>{value}</span> {title}</div>
            <div className='savings-sub-header'>
                <KeyCard color='white' stroke='#02B2AF' label='Total Projection for 2024'/>
            </div>
            <OpBarChart type={type}/>
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus} 
                    width={width} 
                    onBenefitScreen={onBenefitScreen}
                />
                <div className='savings-data'>
                    <div className='occupant-savings'>Improvement Goal: <span>{goal}</span></div>
                </div>
            </div>
        </>
    );
};

export default OperationalPage;
