import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://mybuildingalerts.com/";


export const createServiceRecord = async (alertId, buildingId) => {
    try {
        const response = await axios.post(`${API_URL}/servicerecords`, {
            alertId,
            buildingId
        } , {
            withCredentials: true,
        });
        
        return response.data;
    } catch (error) {
        console.error("Failed to create service record", error);
        throw error;
    }
};

export const updateServiceRecord = async (id, data) => {
    try {
        const response = await axios.put(`${API_URL}/servicerecords/${id}`, data, {
            withCredentials: true,
        });
        
        return response.data;
    } catch (error) {
        console.error("Failed to update service record", error);
        throw error;
    }

};

export const updateServiceDescription = async (id, type, description) => {
    const updateData = {
        [type]: { description }
    };
    return await updateServiceRecord(id, updateData);
};

export const uploadServiceImage = async (id, image, serviceStage) => {
    try {
        console.log(id);
        console.log(image);
        console.log(serviceStage);
        const formData = new FormData();
        formData.append('serviceRecordId', id);
        formData.append('image', image);
        formData.append('serviceStage', serviceStage);

        const response = await axios.post(`${API_URL}/images/building`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        });

        return response.data;
    } catch (error) {
        console.error("Failed to update service record", error);
        throw error;
    }
};