import React, { useEffect, useState } from 'react';
import '../../MainDash/Alerts/AlertInfoPage.css';
import '../../MainDash/NewHome.css';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AlertService from '../../../services/AlertService';
import { useHistory } from 'react-router-dom';
import { getUserId } from '../../../utils/AuthUtilities';
import { AddButton } from './AddButton';

const BuildingInfo = ({ type }) => {
    const history = useHistory();
    const user_id = getUserId();
    const [user, setUser] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Utility function to fetch details based on type
    const fetchDetails = async (id, type) => {
        try {
            if (type === 'Tenant') {
                const tenantData = await AlertService.getTenantById(id);
                return tenantData;
            } else if (type === 'Building') {
                const buildingData = await AlertService.getBuildingById(id);
                return buildingData;
            }
        } catch (error) {
            console.error(`Failed to fetch ${type} details:`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await AlertService.getUserById(user_id);
                setUser(userData);

                // Fetch details for either tenants or buildings based on type
                const details = await Promise.all(
                    userData[type.toLowerCase() + 's'].map((id) => fetchDetails(id, type))
                );
                setDataList(details.filter(detail => detail !== null)); // Filter out any null values
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [user_id, type]);

    if (isLoading) {
        return (
            <div className='loader-container'>
                <div className="loader"></div>
            </div>
        );
    }
    
    const handleItemClick = (item) => {
        const { _id } = item;
        
        history.push(`/DataPage/${type}/${_id}`, {
            item,
            type,  
        });
    };

    return (
        <div className="AlertPage">
            <div className="alerts-section">
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.push('/Home')}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)' }}
                            />
                        </IconButton>
                        <h2>{type} List</h2>
                        <div className="placeholder" style={{ width: '40px', backgroundColor: 'transparent' }}></div>
                    </div>
                </div>

                <div className="item-list">
                    {dataList.map((item) => (
                        <div key={item._id} className="item" 
                            onClick={() => handleItemClick(item)}
                            style={type === 'Building' ? { gap: '10px' } : {}}>
                            <h3>{item.name}</h3>
                            <p>{item.address}</p> 
                        </div>
                    ))}
                </div>

                <div className='alert-button-container' style={{padding: '0 1.25em 1em 1em', display: 'flex', justifyContent: 'flex-end'}}>
                    <AddButton />
                </div>
            </div>
        </div>
    );
};

export default BuildingInfo;
