import AlertPage from './AlertPage'
import NewAlertCard from './NewAlertCard';
import AlertService from '../../../services/AlertService';
import { getAlertType, getAlertSeverity } from '../../../utils/AlertUtilities';
import { getCurrentBuilding } from '../../../utils/AuthUtilities';
import './AlertPage.css';
import '../NewHome.css';

import React, { useEffect, useState } from 'react';

const AlertTypePage = ({ alertType, title }) => {
    const buildingId = getCurrentBuilding();
    const [alerts, setAlerts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        const fetchAlert = async () => {
            try {
                const alertsData = await AlertService.getAlertsByBuildingId(buildingId);
                const resourceAlerts = alertsData.filter(alert => 
                    alertType.includes(getAlertType(alert.alert_id))
                );
                setAlerts(resourceAlerts);
                
            } catch (error) {
                setError('Failed to fetch alerts.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchAlert();
    }, [buildingId, alertType]);


    return (
        <AlertPage title={title}>
            {loading ? (
                <div className='loader-container'>
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : alerts.length > 0 ? (
                alerts.map(alert => {
                    return(
                    <NewAlertCard
                        key={alert._id}
                        status={alert.status}
                        severity={getAlertSeverity(alert.alert_id)}
                        description={alert.alert_name}
                        alertObj={alert}
                    />
                    );
                })
            ) : (
                <div className='no-alerts'>No alerts found</div>
            )}
    </AlertPage>    
  );

};

export default AlertTypePage;

// Function to fetch alerts for specific alert types
const fetchAlertCounts = async (types, buildingId) => {
    try {
        const alertsData = await AlertService.getAlertsByBuildingId(buildingId);
        
        const filteredAlerts = alertsData.filter(alert =>
            types.includes(getAlertType(alert.alert_id))
        );
        return filteredAlerts;
    } catch (error) {
        console.error('Failed to fetch alerts for types', types, error);
        return [];
    }
};

export { fetchAlertCounts };