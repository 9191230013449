import React from 'react';
import PropTypes from 'prop-types';
import { GoalData } from './GoalData';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const GoalPage = ({ type }) => {
  const goals = GoalData[type] || [];
  const [score, setScore] = React.useState('');

  const handleChange = (event) => {
    setScore(event.target.value);
  };

  return (
    <div style={{marginTop: '10px'}}>
      {goals.map((goal, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', }}>
            <div style={{width: '60%'}}>
              <p style={{fontWeight: 'bold', textAlign: 'left', color: '#5C623D', margin: 0, fontSize: '17px'}}>{goal.description}</p>
              {goal.previousYear !== "" && (
                <p style={{color: '#00000099', textAlign: 'left', marginLeft: '15px'}}>2023: {goal.previousYear}</p>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
              
              <TextField
                    label="Goal"
                    // id="standard-start-adornment"
                    sx={{ m: 1, }}
                    size='small'
                    defaultValue={goal.currentGoal}
                    InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>
          </div>
        </div>
      ))}

      {type === 'satisfaction' && (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', }}>
        <div style={{width: '60%'}}>
          <p style={{fontWeight: 'bold', textAlign: 'left', color: '#5C623D', margin: 0, fontSize: '17px'}}>
          Average a 4+ on the Quarterly Occupant Satisfaction Survey</p>
          <p style={{color: '#00000099', textAlign: 'left', marginLeft: '15px'}}>
          Scale of 1-5, 1 is Poor and 5 is Excellent</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
        <FormControl sx={{ m: 0.5, minWidth: 80 }} size="small">
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Goal Score
            </InputLabel>
            <NativeSelect
              defaultValue={4}
            >
            <option value={5}>5</option>
            <option value={4}>4</option>
            <option value={3}>3</option>
            <option value={2}>2</option>
            <option value={1}>1</option>
          </NativeSelect>
        </FormControl>
        </div>
      </div>
      )}


      <button className='alert-info-button' style={{padding: '0.35em 1.5em', fontSize: '18px'}}>
        Save
      </button>
    </div>
  );
};

GoalPage.propTypes = {
  type: PropTypes.oneOf(['resource', 'operational', 'satisfaction']).isRequired,
};

export default GoalPage;
